import * as React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Seo from '../components/Seo'
import Navigation from '../components/Navigation'
import CommissionBody from '../components/CommissionBody'

// Context lets us pass a value deep into the component tree
// without explicitly threading it through every component.
// Create a context for the current theme (with "light" as the default).
export const LanguageContext = React.createContext('English');
export const ThemeContext = React.createContext('Light');

// markup
const IndexPage = () => {
  return (
    <ThemeContext.Provider value="Dark">
      <LanguageContext.Provider value={"English"}>
        <main>
          <Seo
            title='Matthe815.dev'
          />

          <CommissionBody />

        </main>
      </LanguageContext.Provider>
    </ThemeContext.Provider>
  )
}

export default IndexPage
